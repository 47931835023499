body {
  margin: 0px;
  padding: 0px;
  background: #f2f2f2;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #092b1c;
  line-height: 1.5;
  overflow-x: hidden;
}
.mainheader {
  background: lightpink;
  width: 100%;
  height: 130px;
  margin: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  text-align: center;
  z-index: 1000;
}

.vanbox {
  float: left;
  width: 10%;
  padding-top: 10px;
  text-align: center;
}
.vanbox strong {
  width: 100%;
}

.bookingslot {
  overflow-y: scroll !important;
}

.bookingslot::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #c7c6c9;
}

.bookingslot::-webkit-scrollbar {
  width: 5px;
}

.locationtext {
  line-height: 12px;
  max-height: 50px;
  color: #092b1c;
  font-size: 16px;
}

.bookingslot::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ecebeb;
}

.slotcontainer:last-child {
  margin: 0px;
}

.vanbox-1 {
  margin-left: 5px;
  margin-right: 30px;
}
.vanbox-2 {
  margin-right: 60px;
}
.vanbox-3 {
  margin-right: 20px;
}
.vanbox-4 {
  margin-right: 25px;
}
.vanbox-5 {
  margin-right: 30px;
}
.vanbox-6 {
  margin-right: 10px;
  margin-left: 6px;
}


.vanbox p {
  padding: 0px;
  margin: 0px;
  height: 40px !important;
  max-height: 50px !important;
  overflow: hidden;
}

@media screen and (max-width: 5000px) {
  .vanbox {
    width: 11.2%;
  }
}

@media screen and (max-width: 2500px) {
  .vanbox {
    width: 11%;
  }
}
@media screen and (max-width: 2300px) {
  .vanbox {
    width: 10.8%;
  }
}
@media screen and (max-width: 2150px) {
  .vanbox {
    width: 10.5%;
  }
}
@media screen and (max-width: 1980px) {
  .vanbox {
    width: 10.3%;
  }
}
@media screen and (max-width: 1880px) {
  .vanbox {
    width: 10%;
  }
}
@media screen and (max-width: 1750px) {
  .vanbox {
    width: 9.8%;
  }
}
@media screen and (max-width: 1670px) {
  .vanbox {
    width: 9.5%;
  }
}
@media screen and (max-width: 1570px) {
  .vanbox {
    width: 9.3%;
  }
}
@media screen and (max-width: 1510px) {
  .vanbox {
    width: 9.1%;
  }
}

@media screen and (max-width: 1440px) {
  .vanbox {
    width: 8.9%;
  }
}
@media screen and (max-width: 1390px) {
  .vanbox {
    width: 8.6%;
  }
}
@media screen and (max-width: 1315px) {
  .vanbox {
    width: 8.4%;
  }
}
@media screen and (max-width: 1270px) {
  .vanbox {
    width: 8%;
  }
}

@media screen and (max-width: 1270px) {
  .vanbox {
    width: 8%;
  }
}

@media only screen and (max-width: 1024px) {
  .vanbox {
    width: 5.8%;
  }
  .vanbox h4 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .vanbox {
    width: 2.7%;
  }
  .vanbox h4 {
    font-size: 20px !important;
  }
}

.Overlay-transition {
  position: fixed !important;

  height: 800px !important;
  width: 680px !important;
  z-index: 9999 !important;
}
.Layout-root css-jrrpff {
  width: 1000px !important;
}
.css-jrrpff.Layout-root {
  padding-bottom: 100px !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.login-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f2f2;
  z-index: 9999;
}

.login-form {
  width: 400px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}
